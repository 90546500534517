import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';
import Bowser from 'bowser';

import AddIcon from '@mui/icons-material/Add';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PlaceIcon from '@mui/icons-material/Place';
import PublicIcon from '@mui/icons-material/Public';

import AuthContext from '../../context/AuthContext';

import { setBottomBarValue } from '../../features/app/appSlice';
import {
  resetDialogs,
  setGeolocationDeniedDialogOpen,
  setLocationPermissionDialogOpen,
  setSavedEventsDialogOpen,
  setUserEventsDialogOpen,
} from '../../features/app/dialogsSlice';
import { setAuthDialogOpen } from '../../features/dialogs/authDialogSlice.ts';
import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import { setNewEventCoordinates } from '../../features/dialogs/newEventDialogSlice.ts';
import {
  setCreateNewMenuAnchorEl,
  setCreateNewMenuCenter,
} from '../../features/map/createNewMenuSlice.ts';

import {
  backdropBlur,
  dimGray,
  equalBoxShadow,
  fabBoxShadow,
  navbarBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { isOlderVersion } from '../../utils/utils';

const BottomNavigationActionLeft = styled(BottomNavigationAction)`
  color: white;
  height: 100%;
  
  .MuiTouchRipple-root {
    border-radius: 25px 0 0 25px;
    margin-right: -10px;
  }
`;

const BottomNavigationActionRight = styled(BottomNavigationAction)`
  color: white;
  height: 100%;

  .MuiTouchRipple-root {
    border-radius: 0 25px 25px 0;
    margin-left: -10px;
  }
`;

const BottomNavigationWrapper = styled(Box)`
  position: fixed;
  bottom: 5vh;
  left: 5vw;
  right: 5vw;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
  box-shadow: ${navbarBoxShadow};
  z-index: 11;
`;

const CenterButton = styled(Fab)`
  width: 9vh;
  height: 9vh;
  display: flex;
  flex-direction: column;
`;

const FabsWrapper = styled(Box)`
  position: fixed;
  bottom: 3vh;
  right: 1.5vh;
  z-index: 4;
`;

const StyledAddIcon = styled(AddIcon)`
  font-size: 3.5rem;
`;

const StyledBookmarkIcon = styled(BookmarkIcon)`
  margin-right: 0.5rem;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  background-color: rgba(23, 23, 23, 0.6);
  height: 7vh;
  border-radius: 25px;
  align-items: center;
  backdrop-filter: ${backdropBlur};
`;

const StyledFab = styled(Fab)`
  color: #fff;
  background-color: transparent;
  box-shadow: ${fabBoxShadow};
  backdrop-filter: ${backdropBlur};

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

const StyledPlaceIcon = styled(PlaceIcon)`
  margin-right: 0.5rem;
`;

const StyledPublicIcon = styled(PublicIcon)`
  font-size: 2.5rem;
`;

const BottomBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);
  const userPosition = useSelector((state: any) => state.userPosition.value);
  const locationAllowed = useSelector((state: any) => state.map.locationAllowed);
  const geolocateControl = useSelector((state: any) => state.map.geolocateControl);
  const geolocationPermissionStatus = useSelector((state: any) => state.map.geolocationPermissionStatus);

  const width = useSelector((state: any) => state.app.width);

  const value = useSelector((state: any) => state.app.bottomBarValue);
  const userEventsDialogOpen = useSelector((state: any) => state.dialogs.userEventsDialogOpen);
  const savedEventsDialogOpen = useSelector((state: any) => state.dialogs.savedEventsDialogOpen);
  const eventDialogOpen = useSelector((state: any) => state.eventDialog.open);

  const isMapDragged = useSelector((state: any) => state.map.isDragged);
  const anyDialogOpen = useSelector((state: any) => state.dialogs.anyDialogOpen) || eventDialogOpen;

  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const os = parsedUserAgent.os;

  const handleSavedEventsClick = () => {
    if (user) {
      if (savedEventsDialogOpen) {
        dispatch(setSavedEventsDialogOpen(false));
        dispatch(setBottomBarValue(1));
      } else {
        dispatch(setSavedEventsDialogOpen(true));
      };
    } else {
      dispatch(setAuthDialogOpen(true));
      dispatch(setBottomBarValue(1));
    };
  };

  const handleCenterFabClick = (e) => {
    if (anyDialogOpen) {
      dispatch(setEventDialogOpen(false));
      dispatch(setAuthDialogOpen(false));
      dispatch(resetDialogs());
      dispatch(setBottomBarValue(1));
    } else {
      if (
        (
          locationAllowed &&
          geolocationPermissionStatus === 'granted') ||
        (
          os.name === 'iOS' &&
          !isOlderVersion(os.version, '18.2') &&
          geolocationPermissionStatus === 'prompt'
        )
      ) {
        if (user) {
          if (userPosition.length === 2) {
            if (geolocateControl._watchState !== 'ACTIVE_LOCK') {
              geolocateControl.trigger();
            };
            dispatch(setNewEventCoordinates(userPosition));
            dispatch(setCreateNewMenuCenter(true));
            dispatch(setCreateNewMenuAnchorEl(e.currentTarget));
          };
        } else {
          dispatch(setAuthDialogOpen(true));
          dispatch(setBottomBarValue(1));
        };
      } else {
        if (
          geolocationPermissionStatus === 'denied' ||
          (
            os.name === 'iOS' &&
            (
              geolocationPermissionStatus === 'prompt' ||
              typeof geolocationPermissionStatus === 'undefined'
            )
          )
        ) {
          dispatch(setGeolocationDeniedDialogOpen(true));
        } else {
          dispatch(setLocationPermissionDialogOpen(true));
        };
      };
    };
  };

  const handleUserEventsClick = () => {
    if (user) {
      if (userEventsDialogOpen) {
        dispatch(setUserEventsDialogOpen(false));
        dispatch(setBottomBarValue(1));
      } else {
        dispatch(setUserEventsDialogOpen(true));
      };
    } else {
      dispatch(setAuthDialogOpen(true));
      dispatch(setBottomBarValue(1));
    };
  };

  const geolocationActive = userPosition.length === 2 &&
    (geolocationPermissionStatus === 'granted' ||
      os.name === 'iOS' &&
      geolocationPermissionStatus === 'prompt'
    );

  return (
    <>
      {width < WIDTH_BREAKPOINT ? (
        <BottomNavigationWrapper
          id="bottom-bar"
          sx={{ pointerEvents: isMapDragged ? 'none' : 'auto' }}
        >
          <StyledBottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => dispatch(setBottomBarValue(newValue))}
          >
            <BottomNavigationActionLeft
              label={t('Saved')}
              icon={<BookmarkIcon />}
              onClick={handleSavedEventsClick}
            />
            <CenterButton
              onClick={handleCenterFabClick}
              sx={{
                backgroundColor: geolocationActive || anyDialogOpen ? 'rgba(0, 151, 178, 1)' : dimGray,
                color: geolocationActive || anyDialogOpen ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                boxShadow: geolocationActive || anyDialogOpen ? equalBoxShadow : undefined,
                '&:hover': {
                  backgroundColor: geolocationActive ? 'rgba(0, 151, 178, 1)' : dimGray,
                },
                // background: 'linear-gradient(to top left,rgba(255,255,255,0) 0%,rgba(255,255,255,0) calc(50% - 5px),rgba(255,255,255,1) 50%,rgba(255,255,255,0) calc(50% + 5px),rgba(255,255,255,0) 100%)',
              }}
            >
              {anyDialogOpen ? (
                <StyledPublicIcon />
              ) : (
                <StyledAddIcon />
              )}
            </CenterButton>
            <BottomNavigationActionRight
              label={t('My pins')}
              icon={<PlaceIcon />}
              onClick={handleUserEventsClick}
            />
          </StyledBottomNavigation>
        </BottomNavigationWrapper>
      ) : (
        <FabsWrapper display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <StyledFab
            variant="extended"
            onClick={handleSavedEventsClick}
            sx={{ marginRight: '1rem' }}
          >
            <StyledBookmarkIcon />
            {t('Saved')}
          </StyledFab>
          <StyledFab
            variant="extended"
            onClick={handleUserEventsClick}
          >
            <StyledPlaceIcon />
            {t('My pins')}
          </StyledFab>
        </FabsWrapper>
      )}
    </>
  );
};

export default BottomBar;