import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AuthContext from '../../context/AuthContext';
import DrawerLeft from './DrawerLeft';
import DrawerRight from './DrawerRight';
import EventInvitationsDialog from '../events/EventInvitationsDialog';
import NetworkDialog from '../user/NetworkDialog.tsx';
import NotificationsPanel from './NotificationsPanel.tsx';
import ProfileDialog from '../user/ProfileDialog.tsx';
import SettingsDialog from '../user/SettingsDialog.tsx';
import TextLogo from './TextLogo.tsx';
import UserAvatar from '../user/UserAvatar.tsx';

import {
  setBottomBarValue,
  setDrawerRightOpen,
  setInstalled,
} from '../../features/app/appSlice';

import {
  handleInvitationsClick,
  handleLogin,
  handleLogout,
  handleNetworkClick,
  handleProfileClick,
  handleSettingsClick,
} from '../../utils/handlers';
import { closeDrawer } from '../../utils/utils';

import {
  backdropBlur,
  fabBoxShadow,
  reversedNavbarBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';

// @ts-ignore
import { ReactComponent as PinInvitationsIcon } from '../../assets/pin-invitations-icon.svg';

const ButtonsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const DownloadButton = styled(IconButton)`
  ${!isMobile && css`
    padding: 0;
    margin-right: 12px;

    &:hover {
      border-radius: 25px;
    }

    .MuiTouchRipple-root {
      border-radius: 25px 0 0 25px;
    }
  `}
`;

const MenuPaperPropsSx = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 7,
    width: 10,
    height: 10,
    bgcolor: 'inherit',
    backgroundImage: 'inherit',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
  '& .MuiMenuItem-root': {
    padding: '12px 16px',
  },
};

const SaveAltIconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: ${fabBoxShadow};
`;

const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`;

const StyledPersonOutlineIcon = styled(PersonOutlineIcon)`
  color: #fff;
  font-size: 2rem;
`;

const StyledPinInvitationsIcon = styled(PinInvitationsIcon)`
  width: 1.75rem;
  height: 1.75rem;
`;

const StyledStack = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 25px;
  box-shadow: ${fabBoxShadow};
`;

const StyledToolbar = styled(Toolbar)`
  padding: 0 5vw;
  height: 8vh;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  padding: 0 5px;
`;

const TextLogoWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(Box)`
  flex-grow: 1;
`;

interface TopBarProps {
  bottomBar?: boolean;
};

const TopBar: React.FC<TopBarProps> = ({ bottomBar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);

  const { user, logoutUser } = useContext(AuthContext);

  const eventInvitations = useSelector((state: any) => state.eventInvitations.value);
  const friendsRequests = useSelector((state: any) => state.network.friendsRequests);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);;
  const isMenuOpen = Boolean(anchorEl);
  const drawerRightOpen = useSelector((state: any) => state.app.drawerRightOpen);

  const standaloneDisplayMode = window.matchMedia('(display-mode: standalone)').matches;

  const newEventDialogOpen = useSelector((state: any) => state.newEventDialog.open);
  const editEventDialogOpen = useSelector((state: any) => state.editEventDialog.open);
  const premiumDialogOpen = useSelector((state: any) => state.premiumDialog.open);
  const anyDialogOpen = useSelector((state: any) => state.dialogs.anyDialogOpen)
    || newEventDialogOpen
    || editEventDialogOpen
    || premiumDialogOpen;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (width <= WIDTH_BREAKPOINT) {
      dispatch(setDrawerRightOpen(!drawerRightOpen));
    } else {
      setAnchorEl(e.currentTarget);
    };
  };

  const resetMenus = () => {
    setAnchorEl(null);
    dispatch(setBottomBarValue(1));
  };

  const handleDownloadClick = () => {
    window.location.href = '/download/';
  };

  const _handleInvitationsClick = () => {
    handleInvitationsClick(dispatch, resetMenus);
    closeDrawer(dispatch);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      dispatch(setInstalled(true));
    };
    window.addEventListener('appinstalled', handleAppInstalled);
    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const floatingPathname = ['/', '/download', '/download/', '/about', '/about/'].includes(window.location.pathname);
  const floating =
    floatingPathname &&
    width <= WIDTH_BREAKPOINT &&
    !anyDialogOpen &&
    !drawerRightOpen;

  return (
    <Wrapper sx={{
      top: floating ? '1vh' : 0,
      left: floating ? '2vw' : 0,
      right: floating ? '2vw' : 0,
      position: floatingPathname && width <= WIDTH_BREAKPOINT ? 'fixed' : undefined,
      zIndex: 13,
      borderRadius: floating ? '25px' : undefined,
      width: width <= WIDTH_BREAKPOINT && (anyDialogOpen || drawerRightOpen) ? '100%' : undefined,
      // transition: 'top 0.225s ease, left 0.225s ease',
      // transition: `top ${drawerRightOpen ? 0.195 : 0.225}s ease, left ${drawerRightOpen ? 0.195 : 0.225}s ease`,
      // transition: `top ${drawerRightOpen ? 0.15 : 0.2}s ease, left ${drawerRightOpen ? 0.195 : 0.225}s ease, right ${drawerRightOpen ? 0.195 : 0.225}s ease`,
      // transition: `top ${drawerRightOpen || anyDialogOpen ? 0.01 : 0.01}s ease, right ${drawerRightOpen || anyDialogOpen ? 0.195 : 0.225}s ease`,
      // transition: `top ${drawerRightOpen ? 3 : 5}s ease, left ${drawerRightOpen ? 3 : 5}s ease`,
      // transition: `top ${drawerRightOpen ? 0.195 : 0.225}s ease`,
      backgroundColor: floating ? 'rgba(0, 0, 0, 0.6)' : undefined,
      backdropFilter: floating ? backdropBlur : undefined,
    }}>
      <AppBar position="static" color="primary" sx={{
        boxShadow: floating ? reversedNavbarBoxShadow : 'unset',
        borderRadius: floating ? '25px' : undefined,
        backgroundImage: floating ? 'unset' : undefined,
        backgroundColor: floating ? 'rgba(23, 23, 23, 0.6)' : undefined,
      }}>
        <StyledToolbar id="top-bar">
          <Grid container>
            {width <= WIDTH_BREAKPOINT && (
              <TextLogoWrapper
                item xs={9}
                onClick={() => dispatch(setDrawerRightOpen(false))}
              >
                <TextLogo justify="left" />
              </TextLogoWrapper>
            )}
            <StyledGridItem item xs={width <= WIDTH_BREAKPOINT ? 3 : 12}>
              <ButtonsWrapper>
                {!standaloneDisplayMode && !['/download', '/download/'].includes(window.location.pathname) && (
                  <DownloadButton
                    size="large"
                    color="inherit"
                    onClick={handleDownloadClick}
                  >
                    <Badge badgeContent={!localStorage.getItem('downloadPageVisited') ? 1 : 0} color="primary">
                      {isMobile ? (
                        <SaveAltIconWrapper>
                          <InstallMobileOutlinedIcon />
                        </SaveAltIconWrapper>
                      ) : (
                        <StyledStack direction="row">
                          <InstallDesktopIcon />
                          <StyledTypography>
                            {t('Download')}
                          </StyledTypography>
                        </StyledStack>
                      )}
                    </Badge>
                  </DownloadButton>
                )}
                {user && eventInvitations.length > 0 && (
                  <IconButton
                    size="large"
                    color="inherit"
                    onClick={_handleInvitationsClick}
                  >
                    <Badge badgeContent={eventInvitations.length} color="primary">
                      <StyledPinInvitationsIcon />
                    </Badge>
                  </IconButton>
                )}
                <NotificationsPanel />
                <>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls="mobile-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                    edge="end"
                  >
                    {user ? <UserAvatar /> : <StyledPersonOutlineIcon />}
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    id="mobile-menu"
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={isMenuOpen || false}
                    onClose={handleMenuClose}
                    PaperProps={{ sx: MenuPaperPropsSx }}
                  >
                    {user ? (
                      <div>
                        <MenuItem onClick={() => handleProfileClick(dispatch, resetMenus)}>
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          <Typography variant="body1">
                            {t('Profile')}
                          </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => handleNetworkClick(dispatch, resetMenus)}>
                          <ListItemIcon>
                            <Badge badgeContent={friendsRequests.length} color="primary">
                              <GroupIcon />
                            </Badge>
                          </ListItemIcon>
                          <Typography variant="body1">
                            {t('My community')}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => handleSettingsClick(dispatch, resetMenus)}>
                          <ListItemIcon>
                            <SettingsIcon />
                          </ListItemIcon>
                          <Typography variant="body1">
                            {t('Settings')}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => handleLogout(dispatch, resetMenus, logoutUser, t)}>
                          <ListItemIcon>
                            <Logout />
                          </ListItemIcon>
                          <Typography variant="body1">
                            {t('Logout')}
                          </Typography>
                        </MenuItem>
                      </div>
                    ) : (
                      <MenuItem onClick={() => handleLogin(dispatch, resetMenus)}>
                        <ListItemIcon>
                          <Logout />
                        </ListItemIcon>
                        <Typography variant="body1">
                          {t('Log in')}
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              </ButtonsWrapper>
            </StyledGridItem>
          </Grid>
        </StyledToolbar>
      </AppBar>
      <EventInvitationsDialog />
      <NetworkDialog />
      {user && (
        <>
          <ProfileDialog />
          <SettingsDialog bottomBar={bottomBar} />
        </>
      )}
      {width <= WIDTH_BREAKPOINT ? (
        <DrawerRight />
      ) : (
        <DrawerLeft />
      )}
    </Wrapper>
  );
};

export default TopBar;