// https://stackoverflow.com/questions/72425509/react-how-to-not-reinitalize-mapbox-gl-js-on-page-change

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Bowser from 'bowser';

import dayjs from 'dayjs';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as turf from "@turf/turf";

import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

import api from '../../utils/api.ts';
import {
  GET_USER_RELATED_EVENTS_INTERVAL,
  GET_USERS_AND_EVENTS_INTERVAL,
  SEND_LOCATION_INTERVAL,
} from '../../consts/intervals';
import {
  CIRCLE_LAYERS,
  DEFAULT_ZOOM,
  DIRECTIONS_CONTROL_STYLE_OVERRIDE,
  EMPTY_GEOJSON,
  EVENT_MARKERS,
  EVENTS_LAYERS,
  MAP_DARK_STYLE_URL,
  MARKER_HEIGHT,
  MAX_ZOOM,
  MIN_ZOOM,
  NAVIGATION_MARKERS,
  NAVIGATION_PITCH,
  PINS_LAYERS,
  SAVED_EVENT_MARKERS,
  USER_EVENT_MARKERS,
  USERS_LAYERS,
} from '../../consts/map';

import AuthContext from '../../context/AuthContext';
import IntervalContext from '../../context/IntervalContext';

import CompassFab from './CompassFab';
import CreateNewMenu from '../ui/CreateNewMenu';
import RangeSpeedDial from './RangeSpeedDial.tsx';
import DirectionsProfileControl from './DirectionsProfileControl';
import EventsDrawer from '../events/EventsDrawer';
import GeocontrolFab from './GeocontrolFab';
import GeolocationDeniedDialog from '../ui/GeolocationDeniedDialog.tsx';
import LocationPermissionDialog from '../ui/LocationPermissionDialog.tsx';
import MapFiltersDrawer from './MapFiltersDrawer';
import NewEventDialog from '../events/NewEventDialog';
import NewPinNotAllowedDialog from '../events/NewPinNotAllowedDialog.tsx';

import {
  setGeolocationDeniedDialogOpen,
  setLocationPermissionDialogOpen,
  setNewPinNotAllowedDialogOpen,
  setProfileDialogOpen,
  setWebGLDialogOpen,
} from '../../features/app/dialogsSlice';
import { setWithinMeters } from '../../features/settings/settingsSlice';
import { setEvents } from '../../features/events/eventsSlice';
import {
  setCreateNewMenuCenter,
  setCreateNewMenuAnchorEl,
} from '../../features/map/createNewMenuSlice.ts';
import {
  setDirectionsControl,
  setGeolocateControl,
  setGeolocationPermissionStatus,
  setIsDragged,
  setLocationAllowed,
  setMap,
} from '../../features/map/mapSlice';
import { setUserPosition } from '../../features/settings/userPositionSlice';
import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';

import { MAPBOX_ACCESS_TOKEN } from '../../config';
import {
  backdropBlur,
  dimGray,
  DRAWER_WIDTH,
  fabBoxShadow,
  mainColor,
  WIDTH_BREAKPOINT,
} from '../../theme';
import {
  getSavedEvents,
  getUserEvents,
  getUsers,
} from '../../utils/getters';
import {
  getDistance,
  getMapEventLayers,
  getZoomBasedOnCircle,
} from '../../utils/mapUtils';
import {
  sendChatLog,
  vhToPx,
} from '../../utils/utils';

import { setAuthDialogOpen } from '../../features/dialogs/authDialogSlice.ts';
import {
  setEventDialogEvent,
  setEventDialogOpen,
} from '../../features/dialogs/eventDialogSlice';
import { setNewEventCoordinates } from '../../features/dialogs/newEventDialogSlice.ts';
import {
  setUserDialogOpen,
  setUserDialogUser,
} from '../../features/dialogs/userDialogSlice';

// mapboxgl.accessToken = 'pk.eyJ1IjoicGF3bGFramFrdWIxIiwiYSI6ImNsODliNmQ3ajA1ZGEzb3FvaHZweXMycGEifQ.Xg6184so0GNu_tC-PnN0FA';
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

export default function MapContainer() {
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const userMarkerElement = document.createElement('div');
  userMarkerElement.className = 'user-marker';
  userMarkerElement.style.width = '47px';
  userMarkerElement.style.height = `${MARKER_HEIGHT}px`;
  const userMarker = new mapboxgl.Marker({
    element: userMarkerElement,
    // rotationAlignment: 'horizon',
    anchor: 'bottom',
  });

  const newEventMarkerElement = document.createElement('div');
  newEventMarkerElement.className = 'new-event-marker';
  newEventMarkerElement.style.width = '33px';
  newEventMarkerElement.style.height = `${MARKER_HEIGHT}px`;
  const newEventMarker = new mapboxgl.Marker({
    element: newEventMarkerElement,
    rotationAlignment: 'horizon',
    anchor: 'bottom',
  });

  const width = useSelector((state) => state.app.width);
  const fabSize = useSelector((state) => state.app.fabSize);

  const [backdropOpen, setBackdropOpen] = useState(true);
  const map = useSelector((state) => state.map.value);
  const isMapDragged = useSelector((state) => state.map.isDragged);

  const mapContainer = useRef(null);
  const [introDialogOpen, setIntroDialogOpen] = useState(false);
  const initialLoad = useRef(true);

  const geolocateControl = useSelector((state) => state.map.geolocateControl);

  const withinMeters = useSelector((state) => state.settings.withinMeters);
  const dispatch = useDispatch();

  const userPosition = useSelector((state) => state.userPosition.value);
  const events = useSelector((state) => state.events.value);
  const userEvents = useSelector((state) => state.userEvents.value);
  const savedEvents = useSelector((state) => state.savedEvents.value);
  const [sharedEvents, setSharedEvents] = useState(EMPTY_GEOJSON);

  const userRef = useRef();
  const { user } = useContext(AuthContext);
  const { addInterval } = useContext(IntervalContext);

  const userPositionRef = useRef();
  const rangeRef = useRef();
  const [rangeCommited, setRangeCommited] = useState(true);
  const mapZoomRef = useRef(1);
  const zoomRef = useRef(DEFAULT_ZOOM);
  const userMarkerRef = useRef();

  const [showFab, setShowFab] = useState(false);
  const [newEventMapMarker, setNewEventMapMarker] = useState(newEventMarker);
  // const [lastLocationCall, setLastLocationCall] = useState(null);

  const displayName = useSelector((state) => state.profile.displayName);

  const lastLocationCallRef = useRef(dayjs());
  const lastEventsCallRef = useRef(null);

  const [circleLayerReady, setCircleLayerReady] = useState(false);
  const [eventsLayerReady, setEventsLayerReady] = useState(false);
  const [usersLayerReady, setUsersLayerReady] = useState(false);
  const [friendsLayerReady, setFriendsLayerReady] = useState(false);
  const [userEventsLayerReady, setUserEventsLayerReady] = useState(false);
  const [savedEventsLayerReady, setSavedEventsLayerReady] = useState(false);
  const [userMarkerReady, setUserMarkerReady] = useState(false);

  const [userEventsInitialCall, setUserEventsInitialCall] = useState(true);
  const [savedEventsInitialCall, setSavedEventsInitialCall] = useState(true);
  const [sharedEventInitialCall, setSharedEventInitialCall] = useState(true);

  const geolocationPermissionStatus = useSelector((state) => state.map.geolocationPermissionStatus);
  const geolocationPermissionStatusRef = useRef();

  const [eventsDrawerOpen, setEventsDrawerOpen] = useState(false);

  const [mapFilterDrawerOpen, setMapFiltersDrawerOpen] = useState(false);
  const mapFilterDrawerOpenRef = useRef(false);

  const lastLayerClickRef = useRef(null);
  const lastMapClickRef = useRef(null);

  const [compassOpen, setCompassOpen] = useState(false);

  const friends = useSelector((state) => state.network.friends);
  const users = useSelector((state) => state.users.value);

  const authDialogOpen = useSelector((state) => state.authDialog.open);
  const editEventDialogOpen = useSelector((state) => state.editEventDialog.open);
  const newEventDialogOpen = useSelector((state) => state.newEventDialog.open);
  const anyDialogOpen = useSelector((state) => state.dialogs.anyDialogOpen)
    // || authDialogOpen
    || editEventDialogOpen
    || newEventDialogOpen;

  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const os = parsedUserAgent.os;
  const introStepperOpen = useSelector((state) => state.dialogs.introStepperOpen);
  const configurationStepperOpen = useSelector((state) => state.dialogs.configurationStepperOpen);

  const plan = useSelector((state) => state.account.plan);

  const circleWidth = 3;
  const circleWidthZoomBreakpoint = plan === 'free' ? 12 : 13;
  const circleDisplayZoomLimit = plan === 'free' ? 9 : 10;
  const defaultLineBlur = 2;
  const lineBlurRef = useRef(defaultLineBlur);
  const startTimeRef = useRef(null);
  const animationRef = useRef(null);

  const handlePlusClick = (e) => {
    dispatch(setNewEventCoordinates(userPosition));
    dispatch(setCreateNewMenuCenter(false));
    dispatch(setCreateNewMenuAnchorEl(e.currentTarget));
  };

  const handleChange = (newValue) => {
    if (newValue !== rangeRef.current) {
      if (rangeCommited) setRangeCommited(false);
      dispatch(setWithinMeters(parseInt(newValue)));
      if (userPosition.length === 2) {
        map.getSource('circleData').setData(
          turf.circle(userPosition, newValue, {
            steps: 80,
            units: 'meters' // or "mile"
          }));
      };
    };
  };

  const handleChangeCommited = async (newValue) => {
    setRangeCommited(true);
    // todo: check if range has changed
    rangeRef.current = parseInt(newValue);
    lastEventsCallRef.current = dayjs();

    if (user) {
      await api.post('/users/settings/', {
        withinMeters: parseInt(newValue),
      });
    };

    if (userPosition.length === 2) {
      lastEventsCallRef.current = dayjs();
      await api.get('/events/', {
        'params': {
          'lng': userPosition[0],
          'lat': userPosition[1],
          'range': newValue,
        }
      }).then(response => {
        dispatch(setEvents(response.data));
      });
      if (userRef.current) {
        getUsers(dispatch, userPosition, newValue);
        // await api.get('/users/friends/').then(response => {
        //   dispatch(setFriends(response.data))
        //   map.getSource('friends').setData({
        //     type: 'FeatureCollection',
        //     features: response.data.features.filter(feature => feature.properties.online),
        //   });
        // });
      };
    };
  };

  const handleMapResize = () => {
    const mapContainer = document.getElementsByClassName('map-container')[0];
    // mapContainer.style.height = `${window.innerHeight - document.getElementById('top-bar').offsetHeight - document.getElementById('bottom-bar').offsetHeight}px`;
    // mapContainer.style.height = `${window.innerHeight - document.getElementById('top-bar').offsetHeight}px`;
    let height = window.innerHeight;
    if (window.innerWidth > WIDTH_BREAKPOINT) height -= document.getElementById('top-bar').offsetHeight;
    mapContainer.style.height = `${height}px`;

    // fixme
    // let geocoderSearch = document.getElementsByClassName('mapboxgl-ctrl-geocoder')[0];
    // if (geocoderSearch) {
    //   if (window.innerWidth <= WIDTH_BREAKPOINT) {
    //     geocoderSearch.classList.add('mapboxgl-ctrl-geocoder--collapsed');
    //   } else {
    //     geocoderSearch.classList.remove('mapboxgl-ctrl-geocoder--collapsed');
    //   };
    // };
  };

  const resetRefs = () => {
    lastLayerClickRef.current = null;
    lastMapClickRef.current = null;
  };

  const resetLocationCallRef = () => {
    lastLocationCallRef.current = null;
  };

  const centerGeolocator = () => {
    if (
      geolocationPermissionStatus === 'granted' ||
      geolocationPermissionStatusRef?.current === 'granted'
    ) {
      // console.log('geolocateControl.options', geolocateControl.options)
      geolocateControl.options.fitBoundsOptions.zoom = getZoomBasedOnCircle(map);
      // geolocateControl.options.fitBoundsOptions.duration = 2000;
      // geolocateControl.options.fitBoundsOptions = {
      //   animate: true,
      //   zoom: getZoomBasedOnCircle(map),
      //   duration: 2000,  // prevent labels flickering
      // };
      if (geolocateControl._watchState !== 'ACTIVE_LOCK') {
        geolocateControl.trigger();
      };
    } else {
      const currentZoom = map.getZoom();
      setShowFab(false);
      map.flyTo({
        duration: 1,
        center: userPositionRef.current,
        zoom: currentZoom <= DEFAULT_ZOOM ? DEFAULT_ZOOM : currentZoom,
      }, { geolocateSource: true });
    };
  };

  const handleWebGLNotSupported = () => {
    dispatch(setWebGLDialogOpen(true));
  };

  const handleWebGLContextLost = async (e) => {
    e.preventDefault();
    if (user) {
      await sendChatLog('WebGL context lost.')
    };
    window.location.reload();
    // initializeMap({ setMap, mapContainer });
  };

  useEffect(() => {
    rangeRef.current = withinMeters;
    userRef.current = user;

    window.addEventListener('resize', handleMapResize);
    handleMapResize();

    const initializeMap = ({ setMap, mapContainer }) => {
      const center = [30, 50];
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAP_DARK_STYLE_URL,
        center: [30, 50],
        zoom: MIN_ZOOM,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        projection: 'globe',
        pitch: 0,
        // interactive: false,
        attributionControl: false,
        // hash: true,
      });
      // if (width < WIDTH_BREAKPOINT) {
      //   map.easeTo({
      //     duration: 0,
      //     padding: { bottom: vhToPx(5 + 7) },  // bottom bar
      //   });
      // };

      // const geocoderResultMarker = document.createElement('div');
      // geocoderResultMarker.className = 'geocoder-result-marker';
      let geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // marker: {
        //   element: geocoderResultMarker,
        // },
        // language: `${languageCode}-${languageCode.toUpperCase()}`,
        // bbox: mapBounds
        // collapsed: true,
        collapsed: window.innerWidth < 400 ? true : false,
        // collapsed: true,
      });

      // map.addControl(geocoder, 'top-left');
      map.addControl(new mapboxgl.AttributionControl(), 'bottom-right');
      // map.addControl(new mapboxgl.NavigationControl({ visualizePitch: true }), 'bottom-right');

      Promise.all(
        NAVIGATION_MARKERS.map(img => new Promise((resolve, reject) => {
          map.loadImage(img.url, function (error, res) {
            if (error) throw error;
            map.addImage(img.id, res);
            resolve();
          });
        }))
      ).then(() => {
        const _directionsControl = new window.MapboxDirections({
          accessToken: mapboxgl.accessToken,
          profile: 'mapbox/walking',
          interactive: false,
          flyTo: false,
          zoom: DEFAULT_ZOOM,
          controls: {
            inputs: false,
            instructions: false,
            profileSwitcher: true,
          },
          styles: DIRECTIONS_CONTROL_STYLE_OVERRIDE,
        });
        map.addControl(_directionsControl);
        dispatch(setDirectionsControl(_directionsControl));
      });

      const _geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
        showAccuracyCircle: false,
        fitBoundsOptions: {
          zoom: DEFAULT_ZOOM,
          duration: 1,  // prevent labels flickering
        }
      });
      dispatch(setGeolocateControl(_geolocateControl));
      map.addControl(_geolocateControl, 'bottom-right');

      map.on('load', () => {
        resetRefs();
        resetLocationCallRef();
        dispatch(setMap(map));
        map.resize();

        let _options = {
          steps: 80,
          units: 'meters',
        };
        map.addSource("circleData", {
          type: "geojson",
          data: turf.circle(turf.point(center), 100, _options),
        });
        map.addLayer({
          "id": "circle",
          "type": "line",
          "source": "circleData",
          "layout": {},
          "paint": {
            'line-color': mainColor,
            'line-blur': lineBlurRef.current,
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              circleDisplayZoomLimit, 0,
              circleWidthZoomBreakpoint, circleWidth,
            ],
          },
        });
        map.addLayer({
          id: "circle-fill",
          type: "fill",
          source: "circleData",
          "paint": {
            "fill-color": mainColor,
            "fill-opacity": 0.1,
          }
        });

        map.addSource('events', {
          'type': 'geojson',
          'data': EMPTY_GEOJSON,
          cluster: true,
          // clusterMaxZoom: 15,
        });
        map.addSource('userEvents', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: userEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
          },
          cluster: true,
        });
        map.addSource('savedEvents', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: savedEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
          },
          cluster: true,
        });
        map.addSource('users', {
          'type': 'geojson',
          'data': EMPTY_GEOJSON,
        });
        map.addSource('friends', {
          'type': 'geojson',
          'data': EMPTY_GEOJSON,
        });

        Promise.all(
          EVENT_MARKERS.map(img => new Promise((resolve, reject) => {
            map.loadImage(img.url, function (error, res) {
              if (error) throw error;
              map.addImage(img.id, res);
              resolve();
            });
          }))
        ).then(() => {
          map.addLayer({
            'id': 'events',
            'type': 'symbol',
            'source': 'events',
            'layout': {
              'icon-image': [
                'match',
                ['get', 'friends'],
                0, 'ongoing-event-marker',
                1, 'ongoing-friend-event-marker',
                'ongoing-event-marker',
              ],
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0,
                9, 0,
                13, 0.4,
                MAX_ZOOM, 0.4,
              ],
              'text-field': ['get', 'mapName'],
              'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold',
              ],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0,
                9, 0,
                13, 12,
                MAX_ZOOM, 12,
              ],
              'text-offset': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, ['literal', [0, 0]],
                9, ['literal', [0, 0]],
                13, ['literal', [0, 0.25]],
                MAX_ZOOM, ['literal', [0, 0.25]],
              ],
              'text-anchor': 'top',
              'text-allow-overlap': true,
            },
            'paint': {
              'text-color': 'white',
            },
            filter: [
              'all',
              ['!has', 'point_count'],
              ['==', '$type', 'Point'],
            ],
          });
          map.addLayer({
            id: 'pins-clusters',
            type: 'circle',
            source: 'events',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#fff',
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0,
                MAX_ZOOM, 30,
              ],
            }
          });
          map.addLayer({
            id: 'pins-cluster-count',
            type: 'symbol',
            source: 'events',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': 'black',
            },
          });
          setEventsLayerReady(true);
        });

        Promise.all(
          USER_EVENT_MARKERS.map(img => new Promise((resolve, reject) => {
            map.loadImage(img.url, function (error, res) {
              if (error) throw error;
              map.addImage(img.id, res);
              resolve();
            });
          }))
        ).then(() => {
          map.addLayer({
            'id': 'userEvents',
            'type': 'symbol',
            'source': 'userEvents',
            'layout': {
              'icon-image': [
                'match',
                ['get', 'timeline'],
                'ongoing', 'user-ongoing-event-marker',
                'upcoming', 'user-upcoming-event-marker',
                'user-ongoing-event-marker',
              ],
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0.15,
                10, 0.3,
                13, 0.4,
                MAX_ZOOM, 0.4,
              ],
              'text-field': ['get', 'mapName'],
              'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold',
              ],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 8,
                10, 9,
                13, 12,
                MAX_ZOOM, 12,
              ],
              'text-offset': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, ['literal', [0, 0]],
                10, ['literal', [0, 0]],
                13, ['literal', [0, 0.25]],
                MAX_ZOOM, ['literal', [0, 0.25]],
              ],
              'text-anchor': 'top',
              'text-allow-overlap': true,
            },
            'paint': {
              'text-color': 'white',
            },
            filter: [
              'all',
              ['!has', 'point_count'],
              ['==', '$type', 'Point'],
            ],
          });
          map.addLayer({
            id: 'user-pins-clusters',
            type: 'circle',
            source: 'userEvents',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#29A1C5',
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0,
                MAX_ZOOM, 30,
              ],
            }
          });
          map.addLayer({
            id: 'user-pins-cluster-count',
            type: 'symbol',
            source: 'userEvents',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': 'white',
            },
          });
          setUserEventsLayerReady(true);
        });

        Promise.all(
          SAVED_EVENT_MARKERS.map(img => new Promise((resolve, reject) => {
            map.loadImage(img.url, function (error, res) {
              if (error) throw error;
              map.addImage(img.id, res);
              resolve();
            });
          }))
        ).then(() => {
          map.addLayer({
            'id': 'savedEvents',
            'type': 'symbol',
            'source': 'savedEvents',
            'layout': {
              'icon-image': [
                'case',
                ['all', ['==', ['get', 'timeline'], 'ongoing'], ['==', ['get', 'friends'], 1]],
                'saved-ongoing-friend-event-marker',
                ['all', ['==', ['get', 'timeline'], 'ongoing'], ['==', ['get', 'friends'], 0]],
                'saved-ongoing-event-marker',
                ['all', ['==', ['get', 'timeline'], 'upcoming'], ['==', ['get', 'friends'], 1]],
                'saved-upcoming-friend-event-marker',
                ['all', ['==', ['get', 'timeline'], 'upcoming'], ['==', ['get', 'friends'], 0]],
                'saved-upcoming-event-marker',
                'saved-ongoing-event-marker',
              ],
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0.15,
                10, 0.3,
                13, 0.4,
                MAX_ZOOM, 0.4,
              ],
              'text-field': ['get', 'mapName'],
              'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold',
              ],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 8,
                10, 9,
                13, 12,
                MAX_ZOOM, 12,
              ],
              'text-offset': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, ['literal', [0, 0]],
                10, ['literal', [0, 0]],
                13, ['literal', [0, 0.25]],
                MAX_ZOOM, ['literal', [0, 0.25]],
              ],
              'text-anchor': 'top',
              'text-allow-overlap': true,
            },
            'paint': {
              'text-color': 'white',
            },
            filter: [
              'all',
              ['!has', 'point_count'],
              ['==', '$type', 'Point'],
            ]
          });
          map.addLayer({
            id: 'saved-pins-clusters',
            type: 'circle',
            source: 'savedEvents',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#004D5B',
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                MIN_ZOOM, 0,
                MAX_ZOOM, 30,
              ],
            }
          });
          map.addLayer({
            id: 'saved-pins-cluster-count',
            type: 'symbol',
            source: 'savedEvents',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': 'white',
            },
          });
          setSavedEventsLayerReady(true);
        });

        map.loadImage(
          'other-user-marker.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('other-user-marker', image);
            map.addLayer({
              'id': 'users',
              'type': 'symbol',
              'source': 'users',
              'layout': {
                'icon-image': 'other-user-marker',
                'icon-allow-overlap': true,
                'icon-anchor': 'bottom',
                'icon-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, 0,
                  9, 0,
                  13, 0.4,
                  MAX_ZOOM, 0.4,
                ],
                'text-field': ['get', 'mapUsername'],
                'text-font': [
                  'Open Sans Semibold',
                  'Arial Unicode MS Bold',
                ],
                'text-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, 0,
                  9, 0,
                  13, 12,
                  MAX_ZOOM, 12,
                ],
                'text-offset': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, ['literal', [0, 0]],
                  9, ['literal', [0, 0]],
                  13, ['literal', [0, 0.25]],
                  MAX_ZOOM, ['literal', [0, 0.25]],
                ],
                'text-anchor': 'top',
                'text-allow-overlap': true,
              },
              'paint': {
                'text-color': 'white',
                // 'circle-radius': 6,
                // 'circle-color': '#B42222'
              },
              'filter': ['==', '$type', 'Point']
            });
            setUsersLayerReady(true);
          });

        map.loadImage(
          'friend-marker.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('friend-marker', image);
            map.addLayer({
              'id': 'friends',
              'type': 'symbol',
              'source': 'friends',
              'layout': {
                'icon-image': 'friend-marker',
                'icon-allow-overlap': true,
                'icon-anchor': 'bottom',
                'icon-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, 0.15,
                  10, 0.3,
                  13, 0.4,
                  MAX_ZOOM, 0.4,
                ],
                'text-field': ['get', 'mapUsername'],
                'text-font': [
                  'Open Sans Semibold',
                  'Arial Unicode MS Bold',
                ],
                'text-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, 8,
                  10, 9,
                  13, 12,
                  MAX_ZOOM, 12,
                ],
                'text-offset': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  MIN_ZOOM, ['literal', [0, 0]],
                  10, ['literal', [0, 0]],
                  13, ['literal', [0, 0.25]],
                  MAX_ZOOM, ['literal', [0, 0.25]],
                ],
                'text-anchor': 'top',
                'text-allow-overlap': true,
              },
              'paint': {
                'text-color': 'white',
                // 'circle-radius': 6,
                // 'circle-color': '#B42222'
              },
              'filter': ['==', '$type', 'Point']
            });
            setFriendsLayerReady(true);
          });

        map.on('click', (e) => {
          setTimeout(() => {
            if (lastMapClickRef.current?.diff(dayjs()) > -1000) return;
            let layers = getMapEventLayers(e, map);
            if (userRef.current
              && userPositionRef.current?.length === 2
              && (os.name !== 'iOS' || geolocationPermissionStatusRef?.current === 'granted')
              && CIRCLE_LAYERS.concat(PINS_LAYERS).every(layer => !layers.includes(layer))
              && !e.originalEvent.target.classList.contains('event-invitation-marker')
            ) {
              dispatch(setNewPinNotAllowedDialogOpen(true));
            };
          }, 250);
        });
        map.on('zoomstart', (e) => {
          const currentZoom = map.getZoom();

          // prevent new event marker on max zoom double click
          if (currentZoom >= MAX_ZOOM) {
            lastLayerClickRef.current = dayjs().add(250, 'millisecond');
            lastMapClickRef.current = dayjs().add(250, 'millisecond');
            // if (_geolocateControl._watchState !== 'ACTIVE_LOCK') {
            //   dispatch(setAlertsSnackbarSeverity('info'));
            //   dispatch(setAlertsSnackbarText(t('Already at max zoom!')));
            //   dispatch(setAlertsSnackbarOpen(true));
            // };
          } else {
            lastLayerClickRef.current = dayjs();
            lastMapClickRef.current = dayjs();
            mapZoomRef.current = currentZoom;
          };
        });
        map.on('zoomend', (e) => {
          resetRefs();

          const currentZoom = map.getZoom();
          if (e.originalEvent) {
            _geolocateControl.options.fitBoundsOptions = {
              zoom: currentZoom <= DEFAULT_ZOOM ? DEFAULT_ZOOM : currentZoom,
              duration: 1,  // prevent labels flickering
            };
          };
        });
        ['pitchstart', 'rotatestart'].forEach(event => {
          map.on(event, (e) => {
            if (!e.geolocateSource) {
              if (map.getBearing() !== 0 || map.getPitch() !== 0) {
                setCompassOpen(true);
              };
              // else {
              //   setTimeout(() => { setCompassOpen(false) }, 750);
              // };
            };
          });
        });
        ['pitchend', 'rotateend'].forEach(event => {
          map.on(event, (e) => {
            if (
              (map.getBearing() === 0 && map.getPitch() === 0) ||
              (map.getPitch() === NAVIGATION_PITCH)
            ) {
              setCompassOpen(false);
            };
          });
        });
        map.on('pitch', () => {
          const pitch = map.getPitch();
          const currentVisibility = map.getLayoutProperty('3d-buildings', 'visibility');
          if (pitch === 0 && currentVisibility !== 'none') {
            map.setLayoutProperty('3d-buildings', 'visibility', 'none');
          } else if (pitch !== 0 && currentVisibility !== 'visible') {
            map.setLayoutProperty('3d-buildings', 'visibility', 'visible');
          };
        });
        map.on('touchstart', (e) => {
          dispatch(setIsDragged(true));
        });
        map.on('touchend', (e) => {
          if (e.originalEvent.touches.length === 0) {
            dispatch(setIsDragged(false));
          };
        });

        map.on('click', CIRCLE_LAYERS, (e) => {
          setTimeout(async () => {
            if (lastLayerClickRef.current?.diff(dayjs()) > -1000) return;
            let layers = getMapEventLayers(e, map);
            if (userRef.current) {
              let lng = e.lngLat.lng;
              let lat = e.lngLat.lat;

              if (!mapFilterDrawerOpenRef.current && (os.name === 'iOS' || geolocationPermissionStatusRef?.current === 'granted')) {
                if (!PINS_LAYERS.some(layer => layers.includes(layer))) {
                  dispatch(setNewEventCoordinates([lng, lat]));
                  newEventMapMarker.setLngLat([lng, lat]).addTo(map);
                  dispatch(setCreateNewMenuCenter(false));
                  dispatch(setCreateNewMenuAnchorEl(newEventMarkerElement));
                  if (!localStorage.getItem('circleClicked') && animationRef.current) {
                    localStorage.setItem('circleClicked', 'true');
                    cancelAnimationFrame(animationRef.current);
                    map.setPaintProperty('circle', 'line-blur', defaultLineBlur);
                    lineBlurRef.current = defaultLineBlur;
                  };
                };
              } else {
                mapFilterDrawerOpenRef.current = false;
                setMapFiltersDrawerOpen(false);
              }
            } else {
              console.info('TODO: not authenticated users')
              if (!PINS_LAYERS.some(layer => layers.includes(layer))) {
                dispatch(setAlertsSnackbarSeverity('info'));
                dispatch(setAlertsSnackbarText(t('Log in to add event!')));
                dispatch(setAlertsSnackbarOpen(true));
              };
            };
          }, 250);
        });
        map.on('mouseenter', CIRCLE_LAYERS, (e) => {
          let layers = getMapEventLayers(e, map);
          if (layers.includes('events')) {
            map.getCanvas().style.cursor = 'pointer';
          } else {
            if (geolocationPermissionStatusRef?.current === 'granted') {
              map.getCanvas().style.cursor = 'cell';
            } else {
              map.getCanvas().style.cursor = 'not-allowed';
            };
          };
        });
        map.on('mouseleave', CIRCLE_LAYERS, (e) => {
          map.getCanvas().style.cursor = 'not-allowed';
        });

        map.on('click', EVENTS_LAYERS, (e) => {
          var feature = e.features[0];
          feature.properties.categories = JSON.parse(feature.properties.categories);
          feature.geometry = feature._geometry;

          dispatch(setEventDialogEvent(feature));
          dispatch(setEventDialogOpen(true));
        });
        map.on('mouseenter', EVENTS_LAYERS, () => {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', EVENTS_LAYERS, (e) => {
          let layers = getMapEventLayers(e, map);
          if (CIRCLE_LAYERS.some(layer => layers.includes(layer))) {
            map.getCanvas().style.cursor = 'cell';
          } else {
            map.getCanvas().style.cursor = 'not-allowed';
          };
        });
        map.on('click', USERS_LAYERS, (e) => {
          dispatch(setUserDialogUser(e.features[0]));
          dispatch(setUserDialogOpen(true));
        });

        map.on('dragend', () => {
          resetRefs();
        });

        var position;

        const animateLineBlur = (time) => {
          if (!startTimeRef.current) startTimeRef.current = time;
          const elapsed = time - startTimeRef.current;
          const lineBlur = 2 + 2 * Math.sin(elapsed / 500);
          lineBlurRef.current = lineBlur;
          map.setPaintProperty('circle', 'line-blur', lineBlurRef.current);
          if (!localStorage.getItem('circleClicked')) {
            animationRef.current = requestAnimationFrame(animateLineBlur);
          };
        };
        animationRef.current = requestAnimationFrame(animateLineBlur);

        _geolocateControl.on('geolocate', async (e) => {
          var lon = e.coords.longitude;
          var lat = e.coords.latitude
          position = [lon, lat];
          dispatch(setUserPosition(position));
          userPositionRef.current = position;

          /* FIXME */
          // map.jumpTo({
          //   speed: 0,
          //   center: position,
          //   zoom: 16,
          //   // zoom: map.getZoom(),
          // });

          // map.flyTo({
          //   duration: 3000,
          //   center: position,
          //   zoom: 16,
          // })
          // map.on('moveend', () => {
          //   map.setPitch(75);
          // })
          var userMarkerLabel = document.createElement('div');
          userMarkerLabel.className = 'user-marker-label';
          userMarker.getElement().addEventListener('click', (e) => {
            e.stopPropagation();
            dispatch(setProfileDialogOpen(true));
          });
          userMarker.getElement().appendChild(userMarkerLabel);
          userMarker.setLngLat(position).addTo(map);
          userMarkerRef.current = userMarker;
          setUserMarkerReady(true);
          map.getSource('circleData').setData(turf.circle(position, rangeRef.current,
            {
              steps: 80,
              units: 'meters',
            }
          ));
          setCircleLayerReady(true);
          let postData = {
            longitude: lon,
            latitude: lat,
          };

          if (position.length === 2 && (!lastEventsCallRef.current || lastEventsCallRef.current.diff(dayjs()) <= -GET_USERS_AND_EVENTS_INTERVAL)) {
            lastEventsCallRef.current = dayjs();
            // console.log('get events from location')
            await api.get('/events/', {
              // const r = await axios.get(`${API_URL}/events/`, {
              'params': {
                'lng': position[0],
                'lat': position[1],
                'range': rangeRef.current,
              }
            }).then(response => {
              dispatch(setEvents(response.data));
            });
            if (userRef.current) {
              getUsers(dispatch, position, rangeRef.current);
              // await api.get('/users/friends/').then(response => {
              //   dispatch(setFriends(response.data))
              //   map.getSource('friends').setData({
              //     type: 'FeatureCollection',
              //     features: response.data.features.filter(feature => feature.properties.online),
              //   });
              // });
            };
          };
          if (userRef.current) {
            if (!lastLocationCallRef.current || lastLocationCallRef.current?.diff(dayjs()) <= -SEND_LOCATION_INTERVAL) {
              lastLocationCallRef.current = dayjs();
              await api.post('/location/', postData);
            };
          };
        });
        _geolocateControl.on('trackuserlocationstart', () => {
          // console.log('A trackuserlocationend event has occurred.');
          setShowFab(false);
          // map.getSource('polygon').setData(emptyGeojson);
          // userMarker.remove();
        });
        _geolocateControl.on('trackuserlocationend', () => {
          // console.log('A trackuserlocationend event has occurred.');
          setShowFab(true);
          // map.getSource('polygon').setData(emptyGeojson);
          // userMarker.remove();
        });
        geocoder.on('result', function (e) {
          map.flyTo({
            center: e.result.center,
            zoom: 16,
            duration: 3000,
          });
        });

        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === 'symbol' && layer.layout['text-field']
        ).id;

        const buildingsBaseValue = 16;
        map.addLayer({
          'id': '3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': buildingsBaseValue,
          'paint': {
            'fill-extrusion-color': '#515252',
            'fill-extrusion-height': [
              'interpolate', ['linear'],
              ['zoom'],
              buildingsBaseValue,
              0,
              buildingsBaseValue + 0.05, ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate', ['linear'],
              ['zoom'],
              buildingsBaseValue,
              0,
              buildingsBaseValue + 0.05, ['get', 'min_height']
            ],
          }
        },
          labelLayerId
        );

        map.setFog({
          'range': [5, 15],
          'horizon-blend': 0.05,
          'color': '#242B4B',
          'high-color': '#161B36',
          'space-color': '#040404',
          'star-intensity': 1
        });
      });
    };

    if (!map) {
      try {
        initializeMap({ setMap, mapContainer });
        document.getElementsByClassName('mapboxgl-canvas')[0]
          .addEventListener('webglcontextlost', handleWebGLContextLost);
      } catch (err) {
        if (err.message.includes('WebGL')) {
          handleWebGLNotSupported();
        } else {
          throw err;
        };
      };
    };
  }, []);

  const fetchSharedEvent = async (pin) => {
    await api.get(`/events/${pin}/`).then(response => {
      setSharedEvents({
        type: 'FeatureCollection',
        features: [
          ...sharedEvents.features,
          response.data,
        ],
      });
      dispatch(setEventDialogEvent(response.data));
      dispatch(setEventDialogOpen(true));
      map.flyTo({
        duration: 1,
        center: response.data.geometry.coordinates,
        zoom: DEFAULT_ZOOM,
      });
      setBackdropOpen(false);
    }).catch(err => {
      setBackdropOpen(false);
      if (err.response.status === 403) {
        dispatch(setAlertsSnackbarAutoHideDuration(3000));
        dispatch(setAlertsSnackbarSeverity('warning'));
        dispatch(setAlertsSnackbarText(t('You are not invited to this pin')));
        dispatch(setAlertsSnackbarOpen(true));
      } else if (err.response.status === 404) {
        dispatch(setAlertsSnackbarAutoHideDuration(3000));
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('No such pin!')));
        dispatch(setAlertsSnackbarOpen(true));
      } else if (err.response.status === 406) {
        dispatch(setAlertsSnackbarAutoHideDuration(3000));
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('You have to log in to see this pin')));
        dispatch(setAlertsSnackbarOpen(true));
        dispatch(setAuthDialogOpen(true));
      };
    });
  };

  useEffect(() => {
    if (map) {
      const pin = searchParams.get('pin');
      const location = searchParams.get('location')?.split(',');
      if (pin) {
        setSharedEventInitialCall(false);
        fetchSharedEvent(pin);
      } else if (location?.length === 2) {
        map.flyTo({
          duration: 1,
          center: location,
          zoom: DEFAULT_ZOOM,
        });
        setBackdropOpen(false);
      } else {
        setBackdropOpen(false);
      };
    };
  }, [map]);

  useEffect(() => {
    if (map) {
      const pin = searchParams.get('pin');
      if (pin && user && sharedEventInitialCall) {
        setSharedEventInitialCall(false);
        fetchSharedEvent(pin);
      };
      const sharedEvent = sharedEvents.features[0];
      if (!user && sharedEvent?.properties.private) {
        setSharedEvents(EMPTY_GEOJSON);
        dispatch(setEventDialogOpen(false));
      };
    };
  }, [user]);

  useEffect(() => {
    if (anyDialogOpen) setSearchParams([]);
  }, [anyDialogOpen]);

  useEffect(() => {
    var interval;
    if (map) {
      if (user && savedEventsLayerReady && userEventsInitialCall) {
        setUserEventsInitialCall(false);
        getSavedEvents(dispatch, { past: false });
      };
      if (user && userEventsLayerReady && savedEventsInitialCall) {
        setSavedEventsInitialCall(false);
        getUserEvents(dispatch, { past: false });
      };

      interval = addInterval(() => {
        if (user && userEventsLayerReady && savedEventsInitialCall) {
          getUserEvents(dispatch, { past: false });
          getSavedEvents(dispatch, { past: false });
        };
      }, GET_USER_RELATED_EVENTS_INTERVAL);
    };

    return () => clearInterval(interval);
  }, [userEventsLayerReady, savedEventsLayerReady, user]);

  useEffect(() => {
    setUserEventsInitialCall(Boolean(user));
    setSavedEventsInitialCall(Boolean(user));
    setSharedEventInitialCall(Boolean(user));
  }, [user]);

  useEffect(() => {
    if (map) {
      map.getSource('userEvents').setData({
        type: 'FeatureCollection',
        features: [
          ...sharedEvents.features.filter(e => e.properties.own && !e.properties.saved),
          ...userEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
        ]
      });
    };
  }, [userEvents.features]);

  useEffect(() => {
    if (map) {
      map.getSource('events').setData({
        type: 'FeatureCollection',
        features: [
          ...sharedEvents.features.filter(e => !e.properties.own && !e.properties.saved),
          ...events.features.filter(e => e.properties.timeline === 'ongoing' && !e.properties.cancelled),
        ],
      });
    };
  }, [events.features, savedEvents.features, sharedEvents.features]);

  useEffect(() => {
    if (map) {
      map.getSource('savedEvents').setData({
        type: 'FeatureCollection',
        features: [
          ...sharedEvents.features.filter(e => !e.properties.own && e.properties.saved),
          ...savedEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
        ]
      });
    };
  }, [savedEvents.features, sharedEvents.features]);

  useEffect(() => {
    if (!map) { return };
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    } else {
      if (isMobile) {
        if (navigator.permissions) {
          navigator.permissions.query({ name: 'geolocation' })
            .then((ps) => {
              console.log(1, ps.state)
              if (ps.state === 'granted' || (os.name === 'iOS' && ps.state === 'prompt')) {
                const pin = searchParams.get('pin');
                const location = searchParams.get('location')?.split(',');
                dispatch(setLocationAllowed(true));
                if (map && geolocateControl._watchState === 'OFF' && !pin && location?.length !== 2) {
                  geolocateControl.trigger();
                };
              } else if (os.name !== 'iOS' && ps.state === 'prompt') {
                // setLocationPermissionDialogOpen(!introDialogOpen);
                if (!introStepperOpen && !configurationStepperOpen) {
                  dispatch(setLocationPermissionDialogOpen(true));
                };
              } else if (ps.state === 'denied') {
                navigator.geolocation.getCurrentPosition(
                  position => { },
                  error => {
                    if (error.code === error.PERMISSION_DENIED) {
                      if (!introDialogOpen) {
                        dispatch(setGeolocationDeniedDialogOpen(true));
                      };
                    } else { };
                  },
                );
              };
            });
        } else {
          console.log('no navigator.permissions', navigator.geolocation)
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pin = searchParams.get('pin');
              const location = searchParams.get('location')?.split(',');
              dispatch(setLocationAllowed(true));
              if (map && geolocateControl._watchState !== 'ACTIVE_LOCK' && !pin && location?.length !== 2) {
                geolocateControl.trigger();
              };
              dispatch(setGeolocationPermissionStatus('granted'));
            },
            error => {
              console.log('error', error);
              if (error.code === error.PERMISSION_DENIED) {
                dispatch(setGeolocationPermissionStatus('denied'));
                if (!introDialogOpen) {
                  dispatch(setGeolocationDeniedDialogOpen(true));
                };
              } else { };
            },
          );
        };
      };
    };
  }, [introDialogOpen, initialLoad.current, map]);

  useEffect(() => {
    if (geolocationPermissionStatus === 'granted') {
      dispatch(setLocationAllowed(true));
    };
  }, [geolocationPermissionStatus]);

  const _getUsersAndEvents = async () => {
    if (userPosition.length === 2 && (!lastEventsCallRef.current || lastEventsCallRef.current.diff(dayjs()) <= -1000)) {  // FIXME: without <= makes doubled calls
      lastEventsCallRef.current = dayjs();
      await api.get('/events/', {
        'params': {
          'lng': userPosition[0],
          'lat': userPosition[1],
          'range': withinMeters,
        }
      }).then(response => {
        dispatch(setEvents(response.data));
      });
      if (user) {
        getUsers(dispatch, userPosition, withinMeters);
        // await api.get('/users/friends/').then(response => {
        //   dispatch(setFriends(response.data))
        //   map.getSource('friends').setData({
        //     type: 'FeatureCollection',
        //     features: response.data.features.filter(feature => feature.properties.online),
        //   });
        // });
      };
    };
  };

  useEffect(() => {
    _getUsersAndEvents();
  }, [user]);

  const getUsersAndEvents = async () => {
    if (userPosition.length === 2 && (!lastEventsCallRef.current || lastEventsCallRef.current.diff(dayjs()) <= -GET_USERS_AND_EVENTS_INTERVAL)) {
      lastEventsCallRef.current = dayjs();
      // console.log('get events from interval')
      await api.get('/events/', {
        'params': {
          'lng': userPosition[0],
          'lat': userPosition[1],
          'range': withinMeters,
        }
      }).then(response => {
        dispatch(setEvents(response.data));
      });
      if (user) {
        getUsers(dispatch, userPosition, withinMeters);
        // await api.get('/users/friends/').then(response => {
        //   dispatch(setFriends(response.data))
        //   map.getSource('friends').setData({
        //     type: 'FeatureCollection',
        //     features: response.data.features.filter(feature => feature.properties.online),
        //   });
        // });
      };
    };
  };

  useEffect(() => {
    rangeRef.current = withinMeters;
    userRef.current = user;
    const usersAndEventsInterval = addInterval(getUsersAndEvents, GET_USERS_AND_EVENTS_INTERVAL);
    return () => clearInterval(usersAndEventsInterval);
  }, [withinMeters, userPosition, user]);  // fixme: remove user from here

  useEffect(() => {
    if (map && userPosition.length === 2) {
      map.getSource('circleData').setData(turf.circle(userPosition, withinMeters,
        {
          steps: 80,
          units: 'meters'  // or "mile"
        }
      ));
    };
  }, [user, withinMeters]);

  useEffect(() => {
    if (map && userMarkerReady) {
      const markerLabel = document.querySelector('.user-marker-label');
      if (markerLabel) { markerLabel.textContent = displayName };
    };
  }, [displayName, userMarkerReady, user]);  // FIXME

  // useEffect(() => {
  //   ['top', 'bottom'].forEach(tb => {
  //     ['left', 'right'].forEach(lr => {
  //       document.getElementsByClassName(`mapboxgl-ctrl-${tb}-${lr}`)[0].style.pointerEvents = isMapDragged ? 'none' : 'auto';
  //     });
  //   });
  // }, [isMapDragged]);

  useEffect(() => {
    if (circleLayerReady) {
      geolocateControl.options.fitBoundsOptions = {
        zoom: getZoomBasedOnCircle(map),
        duration: 1,  // prevent labels flickering
      };
      if (geolocateControl._watchState !== 'ACTIVE_LOCK') {
        geolocateControl.trigger();
      };
    };
  }, [circleLayerReady, geolocateControl]);

  useEffect(() => {
    if (friendsLayerReady) {
      map.getSource('friends').setData({
        type: 'FeatureCollection',
        features: friends.features.filter(feature => feature.properties.online),
      });
    };
  }, [friends, friendsLayerReady]);

  useEffect(() => {
    if (usersLayerReady) {
      map.getSource('users').setData(users);
    }
  }, [users, usersLayerReady]);

  useEffect(() => {
    return () => {
      resetRefs();
      resetLocationCallRef();
    };
  }, []);

  useEffect(() => {
    const handleAppClose = () => {
      resetRefs();
      resetLocationCallRef();
    };

    window.addEventListener('beforeunload', handleAppClose);

    return () => {
      window.removeEventListener('beforeunload', handleAppClose);
    };
  }, []);

  const postUserLocation = async () => {
    if (lastLocationCallRef.current.diff(dayjs()) <= -SEND_LOCATION_INTERVAL) {
      let postData = {
        longitude: userPosition[0],
        latitude: userPosition[1],
      };
      lastLocationCallRef.current = dayjs();
      await api.post('/location/', postData);
    };
  };

  useEffect(() => {
    var interval;
    if (user && userPosition.length === 2) {
      interval = setInterval(() => {
        postUserLocation();
      }, SEND_LOCATION_INTERVAL);
    };
    return () => clearInterval(interval);
  }, [user, userPosition]);

  useEffect(() => {
    if (map && userMarkerRef.current && os.name !== 'iOS') {
      const locationOn = geolocationPermissionStatus === 'granted';
      map.setPaintProperty('circle', 'line-color', locationOn ? mainColor : dimGray);
      map.setPaintProperty('circle-fill', 'fill-color', locationOn ? mainColor : dimGray);
      map.setPaintProperty('circle-fill', 'fill-opacity', locationOn ? 0.1 : 0.2);
      userMarkerRef.current._element.style.filter = locationOn ? '' : 'grayscale(1)';
    };
  }, [
    os.name,
    map,
    userMarkerRef.current,
    geolocationPermissionStatus,
  ]);

  useEffect(() => {
    geolocationPermissionStatusRef.current = geolocationPermissionStatus;
  }, [geolocationPermissionStatus, geolocationPermissionStatusRef]);

  useEffect(() => {
    if (
      map &&
      userMarkerRef.current &&
      userPositionRef.current?.length === 2 &&
      rangeRef.current &&
      rangeCommited
    ) {
      const pinWithinTreshold = [
        ...events.features,
        ...userEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
        ...savedEvents.features.filter(e => e.properties.timeline !== 'past' && !e.properties.cancelled),
        ...users.features,
        ...friends.features.filter(feature => feature.properties.online),
      ].some(e => getDistance(e.geometry.coordinates, userPositionRef.current) < 0.25 * rangeRef.current);

      const setOpacity = (opacity) => {
        document.getElementsByClassName('mapboxgl-user-location-dot')[0].style.opacity = opacity;
        document.getElementsByClassName('mapboxgl-user-location-heading')[0].style.opacity = opacity;
      };

      if (pinWithinTreshold) {
        userMarkerRef.current.addClassName('opaque-marker');
        setOpacity(0.2);
      } else {
        userMarkerRef.current.removeClassName('opaque-marker');
        setOpacity(1);
      };
    }
  }, [
    map,
    userMarkerRef.current,
    userPositionRef.current,
    events.features.length,
    userEvents.features,
    savedEvents.features.length,
    users.features.length,
    friends.features,
    rangeRef.current,
    rangeCommited,
  ]);

  return (
    <div style={{ paddingLeft: width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH}px` }}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 3,
          top: width <= WIDTH_BREAKPOINT ? undefined : '8vh',
        }}
        open={backdropOpen}
      >
        <CircularProgress
          color="inherit"
          sx={{ marginLeft: width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH}px` }}
        />
      </Backdrop>
      <LocationPermissionDialog />
      <GeolocationDeniedDialog />
      <NewPinNotAllowedDialog />
      {userPosition.length === 2 && (
        <RangeSpeedDial
          centerGeolocator={centerGeolocator}
          handleChange={handleChange}
          handleChangeCommited={handleChangeCommited}
        />
      )}
      <NewEventDialog newEventMapMarker={newEventMapMarker} />
      <Grid
        spacing={2}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          position: 'fixed',
          right: '1.5vh',
          top: width <= WIDTH_BREAKPOINT ? '11vh' : '10vh',
          width: 'auto',
          zIndex: 1,
          pointerEvents: isMapDragged ? 'none' : 'auto',
        }}
      >
        <Grid item>
          <EventsDrawer
            open={eventsDrawerOpen}
            setOpen={setEventsDrawerOpen}
          />
        </Grid>
        {/* <Grid item>
          <MapFiltersDrawer
            open={mapFilterDrawerOpen}
            openRef={mapFilterDrawerOpenRef}
            setOpen={setMapFiltersDrawerOpen}
          />
        </Grid> */}
        <Grid item>
          <CompassFab
            setShowFab={setShowFab}
            open={compassOpen}
            setOpen={setCompassOpen}
          />
        </Grid>
      </Grid>
      <Grid
        spacing={2}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          position: 'fixed',
          right: '1.5vh',
          bottom: width <= WIDTH_BREAKPOINT ? `${5 + 7 + 2}vh` : '10vh',
          width: 'auto',
          zIndex: 1,
          pointerEvents: isMapDragged ? 'none' : 'auto',
        }}
      >
        {width > WIDTH_BREAKPOINT && user && userPosition.length > 0 && (
          <Grid item>
            <Fab
              color="primary"
              aria-label="add"
              size={fabSize}
              disabled={geolocationPermissionStatus !== 'granted'}
              onClick={handlePlusClick}
              sx={{
                zIndex: 10,
                backgroundColor: 'rgba(0, 151, 178, 0.25)',
                backdropFilter: backdropBlur,
                fontSize: '3rem',
                boxShadow: fabBoxShadow,
                pointerEvents: isMapDragged ? 'none' : 'auto',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              <AddIcon fontSize="3rem" />
            </Fab>
          </Grid>
        )}
        {/* {width <= WIDTH_BREAKPOINT && ( */}
        <Grid item>
          <GeocontrolFab />
        </Grid>
        {/* )} */}
      </Grid>
      <DirectionsProfileControl setCompassOpen={setCompassOpen} />
      {/* {showFab && (
        <Box display="flex"
          justifyContent="center"
          alignItems="center" >
          <Fab variant="extended" size="small" justify="center" color="info"
            sx={{
              position: 'fixed',
              bottom: width > WIDTH_BREAKPOINT ? '4vh' : '15vh',
              // justifyContent: 'center',
              // textTransform: 'none',
              zIndex: 10,
              pointerEvents: isMapDragged ? 'none' : 'auto',
            }}
            onClick={centerGeolocator}>
            <LocationSearchingIcon sx={{ mr: 1 }} />
            {t('Center')}
          </Fab>
        </Box>
      )} */}
      <CreateNewMenu newEventMapMarker={newEventMapMarker} />
      <div ref={el => (mapContainer.current = el)} className="map-container" />
    </div>
  );
}