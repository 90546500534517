import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../ui/BackButton.tsx';
import CenteredBox from '../ui/CenteredBox.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import PasswordValidation from '../user/PasswordValidation.tsx';
import ShowPasswordButton from '../ui/ShowPasswordButton.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';

import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setBannedUserDialogOpen } from '../../features/app/dialogsSlice';
import {
  setAuthDialogAction,
  setAuthDialogOpen,
} from '../../features/dialogs/authDialogSlice.ts';

import AuthContext from '../../context/AuthContext';

import { APP_NAME } from '../../config';
import {
  DISPLAY_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
} from '../../limits.ts';
import { borderedDialogPaperProps } from '../../theme';
import {
  countCharacters,
  formatName,
  somethingWentWrong,
} from '../../utils/utils';

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-evenly;
  padding-top: 0;
`;

const StyledFormControlLabel1 = styled(FormControlLabel)`
  margin-top: 8px;
  margin-left: -5px;
`;

const StyledFormControlLabel2 = styled(FormControlLabel)`
  margin: 0;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const StyledTypography = styled(Typography)`
  display: flex;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
`;

const AuthDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loginUser,
    registerUser,
    resendActivationEmail,
    resetPassword,
  } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [nickname, setNickname] = useState('');
  const [nicknameError, setNicknameError] = useState(false);
  const [nicknameErrorHelperText, setNicknameErrorHelperText] = useState<string | null>('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordChecklistVisibility, setPasswordChecklistVisbility] = useState('hidden');
  const [passwordsValid, setPasswordsValid] = useState(false)

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const open = useSelector((state: any) => state.authDialog.open);
  const action = useSelector((state: any) => state.authDialog.action);
  const width = useSelector((state: any) => state.app.width);

  const configurationStepperOpen = useSelector((state: any) => state.dialogs.configurationStepperOpen);

  /* const focusUsernameInputField = input => {
    if (input && !isMobile) {
      setTimeout(() => { input.focus() }, 1);
    };
  }; */

  const resetFields = () => {
    setShowPassword(false);
    setUsername('');
    setNickname('');
    setPassword('');
    setPassword2('');
  };

  const enableButtonWithTimeout = () => {
    setTimeout(() => {
      setSubmitButtonDisabled(false);
    }, 1000);
  };

  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (action === 'login') {
      setSubmitButtonDisabled(true);
      await loginUser(username, password).then(response => {
        setLoading(false);
        setSubmitButtonDisabled(false);
        if (response === 200) {
          if (rememberMe) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
          } else {
            localStorage.removeItem('username');
            localStorage.removeItem('password');
          };
          dispatch(setAuthDialogOpen(false));
          dispatch(setAlertsSnackbarSeverity('success'));
          dispatch(setAlertsSnackbarText(t('Logged in!')));
          dispatch(setAlertsSnackbarOpen(true));
          resetFields();
        } else if ([401, 404].includes(response)) {
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('warning'));
          dispatch(setAlertsSnackbarText(t('Invalid login details or inactive account.')));
          dispatch(setAlertsSnackbarOpen(true));
        } else if (response === 403) {
          dispatch(setBannedUserDialogOpen(true));
        } else {
          somethingWentWrong(dispatch, t);
        };
      }).catch(err => {
        setLoading(false);
        somethingWentWrong(dispatch, t);
        enableButtonWithTimeout();
      });
    } else if (action === 'register') {
      if (passwordsValid) {
        setSubmitButtonDisabled(true);
        await registerUser(username, nickname, password, password2).then(response => {
          setLoading(false);
          setSubmitButtonDisabled(false);
          if (response.status === 201) {
            resetFields();
            dispatch(setAuthDialogAction('login'));
            setPasswordChecklistVisbility('hidden');
            dispatch(setAlertsSnackbarAutoHideDuration(7000));
            dispatch(setAlertsSnackbarSeverity('success'));
            dispatch(setAlertsSnackbarText(t('Account created! Check your email to complete the account activation process.')));
            dispatch(setAlertsSnackbarOpen(true));
          } else if (response.status === 409) {
            dispatch(setAlertsSnackbarAutoHideDuration(3000));
            dispatch(setAlertsSnackbarSeverity('info'));
            dispatch(setAlertsSnackbarText(t('Email already in use. Try logging in or use a different one.')));
            dispatch(setAlertsSnackbarOpen(true));
          }
          else if (response.status === 406) {
            setNicknameErrorHelperText(t('Nickname already taken - try another'));
            setNicknameError(true);
          } else {
            somethingWentWrong(dispatch, t);
          };
        }).catch(err => {
          setLoading(false);
          somethingWentWrong(dispatch, t);
          enableButtonWithTimeout();
        });
      };
    } else if (action === 'password-reset') {
      setSubmitButtonDisabled(true);
      await resetPassword(username).then(response => {
        setLoading(false);
        setSubmitButtonDisabled(false);
        if (response.status === 200) {
          resetFields();
          dispatch(setAuthDialogAction('login'));
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('success'));
          dispatch(setAlertsSnackbarText(t('Password reset successful! Check your email for instructions.')));
          dispatch(setAlertsSnackbarOpen(true));
        } else if (response.status === 204) {
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('Your account is inactive. Please activate it via email.')));
          dispatch(setAlertsSnackbarOpen(true));
        } else {
          somethingWentWrong(dispatch, t);
        };
      }).catch(err => {
        setLoading(false);
        somethingWentWrong(dispatch, t);
        enableButtonWithTimeout();
      });
    } else if (action === 'resend-activation-email') {
      setSubmitButtonDisabled(true);
      await resendActivationEmail(username).then(response => {
        setLoading(false);
        setSubmitButtonDisabled(false);
        if (response.status === 200) {
          resetFields();
          dispatch(setAuthDialogAction('login'));
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('success'));
          dispatch(setAlertsSnackbarText(t('Activation email resent! Please check your email.')));
          dispatch(setAlertsSnackbarOpen(true));
        } else if (response.status === 204) {
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('Your account has already been activated. Please log in.')));
          dispatch(setAlertsSnackbarOpen(true));
          setTimeout(() => { dispatch(setAuthDialogAction('login')) }, 3000);
        } else {
          somethingWentWrong(dispatch, t);
        };
      }).catch(err => {
        setLoading(false);
        somethingWentWrong(dispatch, t);
        enableButtonWithTimeout();
      });
    };
  };

  const handleClose = () => {
    dispatch(setAuthDialogOpen(false));
    setTimeout(() => {
      setPasswordChecklistVisbility('hidden');
      resetFields();
      dispatch(setAuthDialogAction('login'));
    }, 100);
  };

  const handleCancel = () => {
    if (configurationStepperOpen) {
      handleClose();
    } else {
      if (['register', 'password-reset'].includes(action)) {
        resetFields(); // fixme
        dispatch(setAuthDialogAction('login'));
      } else if (action === 'resend-activation-email') {
        dispatch(setAuthDialogAction('register'));
      };
      setPasswordChecklistVisbility('hidden');
      setEmailError(countCharacters(username) > EMAIL_MAX_LENGTH);
    };
  };

  const handleRegisterClick = () => {
    resetFields();
    if (action === 'login') {
      dispatch(setAuthDialogAction('register'));
    } else if (['register', 'password-reset', 'resend-activation-email'].includes(action)) {
      dispatch(setAuthDialogAction('login'));
    };
  };

  const handleContactClick = () => {
    handleClose();
    navigate('/contact/');
  };

  const handlePasswordResetClick = () => {
    resetFields();
    dispatch(setAuthDialogAction('password-reset'));
  };

  const handleResendActivationEmailClick = () => {
    resetFields();
    dispatch(setAuthDialogAction('resend-activation-email'));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError(countCharacters(e.target.value) > EMAIL_MAX_LENGTH);
    setUsername(e.target.value);
  };

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nicknameLength = countCharacters(e.target.value);
    setNicknameErrorHelperText(`${nicknameLength}/${DISPLAY_NAME_MAX_LENGTH} ` + t('characters') + ' - ' + t('you can set it in your profile later'));
    setNicknameError(nicknameLength > DISPLAY_NAME_MAX_LENGTH);
    setNickname(formatName(e.target.value));
    setSubmitButtonDisabled(nicknameLength > DISPLAY_NAME_MAX_LENGTH);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (inputRef.current && !isMobile) inputRef.current?.focus();
  }, [action]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (!isMobile) { inputRef.current?.focus() };
      }, 1);
      setSubmitButtonDisabled(false);
    };
  }, [open]);

  useEffect(() => {
    if (open && action === 'login') {
      const storedUsername = localStorage.getItem('username');
      const storedPassword = localStorage.getItem('password');
      if (storedUsername && storedPassword) {
        setUsername(storedUsername);
        setPassword(storedPassword);
        setRememberMe(true);
      };
    };
  }, [open, action]);

  useEffect(() => {
    setSubmitButtonDisabled(emailError);
  }, [emailError]);

  const actionTitles = {
    'login': t('Welcome'),
    'register': t('Join {{appName}}', { appName: APP_NAME }),
    'password-reset': t('Reset password'),
    'resend-activation-email': t('Resend activation email'),
  };

  const actionSubtitles = {
    'login': (
      <StyledTypography variant="body2" mb={1}>
        {t("Log in to see what's new or create an account to start exploring.")}
      </StyledTypography>
    ),
    'register': (
      <StyledTypography variant="body2" mt={1}>
        {t("Log in to see what's new or create an account to start exploring.")}
      </StyledTypography>
    ),
    'password-reset': (
      <StyledTypography variant="caption" mt={1} mb={1}>
        {t("Enter the e-mail address you used to sign up for your account. \
We'll send you instructions on how to reset the password.")}
      </StyledTypography>
    ),
    'resend-activation-email': (
      <StyledTypography variant="caption" mt={1} mb={1}>
        {t("If you didn't receive the activation email we sent earlier \
just fill in your email address below and we'll send you a new one. \
Make sure to check your inbox (and spam folder too, just in case).")}
      </StyledTypography>
    ),
  };

  const endAdornment = (
    <InputAdornment position="end">
      <ShowPasswordButton
        visible={showPassword}
        onClick={handleClickShowPassword}
      />
    </InputAdornment>
  );

  const actionComponents = {
    'login': (
      <>
        <TextField
          inputRef={inputRef}
          margin="dense"
          id="username"
          label={t('Email address')}
          type="email"
          value={username}
          fullWidth
          variant="standard"
          autoComplete="on"
          required
          onChange={handleEmailChange}
          helperText={(emailError && `${countCharacters(username)}/${EMAIL_MAX_LENGTH} ` + t('characters'))}
          error={emailError}
        />
        <TextField
          id="password"
          required
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          value={password}
          fullWidth
          autoComplete="current-password"
          variant="standard"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          InputProps={{ endAdornment: endAdornment }}
        />
        <StyledFormControlLabel1
          control={
            <Checkbox
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />
          }
          label={
            <Typography variant="body2">
              {t('Remember me')}
            </Typography>
          }
        />
      </>
    ),
    'register': (
      <>
        <TextField
          // autoFocus
          // focused
          inputRef={inputRef}
          margin="dense"
          id="username"
          value={username}
          label={t('Email address')}
          type="email"
          fullWidth
          variant="standard"
          autoComplete="on"
          required
          onChange={handleEmailChange}
          helperText={(emailError && `${countCharacters(username)}/${EMAIL_MAX_LENGTH} ` + t('characters'))}
          error={emailError}
        />
        <FormGroup>
          <StyledFormControlLabel2
            label=""
            control={
              <TextField
                onChange={handleNicknameChange}
                id="nickname"
                label={t('Nickname')}
                value={nickname}
                fullWidth
                variant="standard"
                autoComplete="off"
                helperText={nicknameError ? nicknameErrorHelperText : `${countCharacters(nickname)}/${DISPLAY_NAME_MAX_LENGTH} ` + t('characters') + ' - ' + t('you can set it in your profile later')}
                error={nicknameError}
              />
            }
          />
        </FormGroup>
        <TextField
          id="password"
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          value={password}
          fullWidth
          autoComplete="current-password"
          variant="standard"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordChecklistVisbility('visible');
            if (e.target.value === '' && password2 === '') {
              setPasswordChecklistVisbility('hidden');
            };
          }}
          required
          InputProps={{ endAdornment: endAdornment }}
        />
        <TextField
          id="password2"
          label={t('Confirm password')}
          type={showPassword ? 'text' : 'password'}
          value={password2}
          fullWidth
          autoComplete="current-password"
          variant="standard"
          onChange={(e) => {
            setPassword2(e.target.value);
            setPasswordChecklistVisbility('visible');
            if (password === '' && e.target.value === '') {
              setPasswordChecklistVisbility('hidden');
            };
          }}
          required
          InputProps={{ endAdornment: endAdornment }}
        />
        <PasswordValidation
          password1={password}
          password2={password2}
          visibility={passwordChecklistVisibility}
          setValid={setPasswordsValid}
        />
      </>
    ),
    'password-reset': (
      <TextField
        inputRef={inputRef}
        margin="dense"
        id="username"
        label={t('Email address')}
        type="email"
        value={username}
        fullWidth
        variant="standard"
        autoComplete="on"
        required
        onChange={handleEmailChange}
        helperText={(emailError && `${countCharacters(username)}/${EMAIL_MAX_LENGTH} ` + t('characters'))}
        error={emailError}
      />
    ),
    'resend-activation-email': (
      <TextField
        inputRef={inputRef}
        margin="dense"
        id="username"
        label={t('Email address')}
        type="email"
        value={username}
        fullWidth
        variant="standard"
        autoComplete="on"
        required
        onChange={handleEmailChange}
        helperText={(emailError && `${countCharacters(username)}/${EMAIL_MAX_LENGTH} ` + t('characters'))}
        error={emailError}
      />
    ),
  };

  const forgotEmail = (
    <Stack>
      <StyledTypography variant="caption" mt={3} mb={1}>
        {t('Forgot your account email?')}
      </StyledTypography>
      <StyledLink
        type="button"
        variant="caption"
        onClick={handleContactClick}
      >
        {t('Contact us')}
      </StyledLink>
    </Stack>
  );

  const actionInfos = {
    'login': (
      <StyledLink
        type="button"
        variant="caption"
        onClick={handlePasswordResetClick}
      >
        {t('Forgot password?')}
      </StyledLink>
    ),
    'register': (
      <Stack>
        <Typography variant="caption" paddingX={3} mt={1} mb={1}>
          {t('By signing up, you agree to the')} <Link href="/terms-of-service/">
            {t('Terms of Service')}</Link> {t('and')} <Link href="/privacy-policy/">
            {t('_Privacy Policy').replace('_', '')}</Link>.
        </Typography>
        <StyledLink
          type="button"
          variant="caption"
          onClick={handleResendActivationEmailClick}
        >
          {t('Resend activation email')}
        </StyledLink>
      </Stack>
    ),
    'password-reset': forgotEmail,
    'resend-activation-email': forgotEmail,
  };

  const actionButtonTexts = {
    'login': t('Log in'),
    'register': t('Register'),
    'password-reset': t('Submit'),
    'resend-activation-email': t('Submit'),
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      {...borderedDialogPaperProps}
      sx={{ zIndex: 1403 }}
    >
      <DialogContent>
        <form onSubmit={handleSubmit} id="auth-form">
          {action !== 'login' && !configurationStepperOpen && (
            <BackButton
              onClick={handleCancel}
              sx={{ position: 'absolute', left: '10px' }}
            />
          )}
          <CenteredBox>
            <img width={65} height={65} src="/logo-1080.png" />
          </CenteredBox>
          <DialogTitle textAlign="center">
            {actionTitles[action]}{loading && <SmallLoader />}
          </DialogTitle>
          <CloseButton onClick={handleClose} />
          {actionSubtitles[action]}
          {actionComponents[action]}
        </form>
      </DialogContent>
      <StyledDialogActions>
        <Button
          disabled={submitButtonDisabled}
          variant="contained"
          type="submit"
          form="auth-form"
        >
          {actionButtonTexts[action]}
        </Button>
      </StyledDialogActions>
      {actionInfos[action]}
      {action === 'login' && (
        <CenteredBox mb={2}>
          <Button onClick={handleRegisterClick}>
            {t('Create new account')}
          </Button>
        </CenteredBox>
      )}
    </Dialog>
  );
};

export default AuthDialog;